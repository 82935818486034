/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-118cs9b --center --parallax pb--80 pt--80" name={"einleitung"} lightbox={false} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/118/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={null} style={{"maxWidth":1280}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 --center pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":600}}>
              
              <Title className="title-box fs--128 swpf--uppercase" style={{"maxWidth":700}} content={"<span style='color: var(--color-custom-1)'>Frohe<br>Weihnachten<br></span>"}>
              </Title>

              <Title className="title-box title-box--style8 title-box--center fs--62 ls--008" style={{"maxWidth":700}} content={"<span style='color: var(--color-supplementary)'>&amp;<br></span>"}>
              </Title>

              <Title className="title-box swpf--uppercase" style={{"maxWidth":700}} content={"<span style='color: var(--color-supplementary)'>fröhliche<br>Neues Jahr</span>"}>
              </Title>

              <Text className="text-box" content={"<span style='color: var(--color-supplementary)'>Wünscht Ihnen<br>Martin &amp; Andrea</span>"}>
              </Text>

              <Image className="--shape5" alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/118/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":300}} srcSet={"https://cdn.swbpg.com/t/i/template/118/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/118/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/118/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/118/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/118/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box mt--30" content={"<span style='color: var(--color-supplementary)'>Erstellt mit <a href=\"https://saywebpage.com/de/neujahrskarten-2021/\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}